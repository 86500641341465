.CompactCard {
    display: flex;
    flex: 1;
    height: 9rem;
    border-radius: 0.7rem !important;
    color: white;
    position: relative;
    cursor: pointer;
    padding: 1rem
}

.CompactCard:hover {
    box-shadow: none ! important;
}

.radialBar {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}

.CircularProgressbar {
    width: 4rem !important;
    overflow: visible;
}

.CircularProgressbar-path {
    stroke: white !important;
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px white)
}

.CircularProgressbar-trail {
    display: none;
}

.CircularProgressbar-text {
    fill: white !important
}

.radialBar>span {
    font-size: 14px;
    font-weight: bold;
}

.detail {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.detail>span:nth-child(1) {
    padding-top: 30%;
    font-size: 30px;
    font-weight: bold;
}

.detail>span:nth-child(2) {
    font-size: 22px;
}