.values {
  width: 100%;
  padding: 8rem 0 4rem;
  @media screen and (min-width: 968px) {
    padding: 10rem 0 4rem;
  }
  &__container {
    display: flex;
    flex-direction: column;

    &--contents {
      display: grid;
      place-items: center;
      gap: 2rem;
      align-items: stretch;

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .values__content {
        background-color: var(--clr-primary-4);
        padding: 2rem;
        border-radius: 1rem 1rem 0 0;
        cursor: pointer;
        transition: var(--transition);
        display: flex;
        /* align-items: center; */
        // justify-content: center;
        flex-direction: column;
        gap: 1rem;
        text-transform: capitalize;

        &:hover {
          box-shadow: var(--light-shadow);
        }

        &--icon {
          background-color: hsla(350, 100%, 29%, 0.1);
          color: var(--clr-primary-1);
          width: 4rem;
          height: 4rem;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: var(--transition);
          font-size: 1.2rem;
          align-self: center;

          &:hover {
            transform: scale(1.1);
          }
        }

        &--header {
          font-size: 3rem;
          font-weight: 700;
          color: var(--clr-primary-5);
          text-align: center;
        }

        &--desc {
          p {
            font-size: 1.05rem;
            text-align: center;
          }
        }
      }
    }
  }
}
