.Table {
    padding-left: 2%;
    padding-right: 2%;
}

.MuiTableRow-root>* {
    padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head>* {
    font-weight: bold !important;
    font-size: large;
    color: black;
    background-color: #cf95a2;
}

.Table td,
th {
    border: none !important;
}

.Table:first-child {
    border-radius: 0.7rem ;
}

.status {
    padding: 8px;
    border-radius: 9px;
    background-color: #cf95a2;
}

@media screen and (max-width: 1200px) {
    .Table {
        width: 170%;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .Table {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 23rem;
    }
}