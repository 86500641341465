.MainDash {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

h1 {
    padding-left: 2%;
    font-size: large;
    font-weight: bold;
}

/* @media screen and (max-width: 1200px) {
    .MainDash {
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .MainDash {
        align-items: center;
    }
} */