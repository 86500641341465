.Sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4rem;
    transition: all 300ms ease;
}

.logo {
    display: flex;
    height: 5rem;
    font-weight: bold;
    font-size: 22px;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 4%;
}

.logo>img {
    width: 6rem;
    height: 6rem;
}

.logo>span>span {
    color: white;
}

.menu {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
    border-radius: 0.7rem;
    color: aliceblue;
}

.menuItem:hover {
    cursor: pointer;
}

.active {
    background: var(--activeItem);
    margin-left: 0;
}

.active::before {
    content: '';
    width: 8px;
    height: 100%;
    background: var(--pink);
    margin-right: calc(1rem - 8px);
}

.analytics {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.analytics:hover {
    background-color: #cf95a2;
}

.analytics span {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #000;
    text-decoration: none;
}

.menu .menuItem:last-child {
    position: absolute;
    bottom: 2.3rem;
    width: 100%;
}

button:hover {
    background-color: var(--activeItem);
}