.form__wrapper {
    width: 95%;
    margin: 0 auto;

}

.form__wrapper h2{
    margin-top: 1.5rem;
}

.modal__form {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 1rem;
    margin: 1rem auto 0;
    border-radius: 1rem;
}

form label {
    font-size: 1rem;
    font-weight: bold;
    display: block;
    text-align: left;
    margin: 1rem 0 0.2rem;
}

input,
select,textarea {
    width: 100%;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    color: black;
    border-radius: 10px;
    outline: none;
    border: 1px solid transparent;
    background-color: var(--clr-primary-4);
}

input:focus,
select:focus {
    border-color: var(--clr-primary-6);
}

input::placeholder,
select::placeholder {
    color: #a0aec0;
}

input[type="checkbox"] {
    width: fit-content;
    margin-right: 0.5rem;
    transform: scale(1.25);
}

.form-btn,
.thanks-btn {
    display: block;
    margin: 1rem 0;
    padding: 0.9rem 0.5rem;
    background-color: var(--clr-primary-1);
    color: var(--clr-primary-3);
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: var(--spacing);
    cursor: pointer;
    /* transition: var(--transition); */
}

.form-btn:hover,
.thanks-btn:hover {
    background-color: hsla(350, 100%, 29%, 0.9);
}

p.error {
    color: hsl(0deg 88% 54%);
    text-align: initial;
    font-size: 0.8rem;
}

input.input-error,
select.input-error {
    border-color: #fc8181;
}

.form-btn:disabled {
    opacity: 0.35;
}

.form__thanks {
      width: 100%;
      max-width: 400px;
      background-color: white;
      padding: 1rem;
      margin: 0 auto;
      border-radius: 1rem;
      animation-duration: .2s;
      animation-name: bounce;
      animation-timing-function: ease-in-out;
      display: flex;
      flex-direction: column;
      gap: 3rem;
}

@keyframes bounce {
    0% {
        transform: scale(1, 1)
    }

    50% {
        transform: scale(1.1, 1.1)
    }

    100% {
        transform: scale(1, 1)
    }
}

.form__thanks--icon {
    width: 5rem;
    height: 5rem;
    background-color: var(--clr-primary-1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
}

svg.thanks-icon {
    color: white;
    font-size: 2rem;
}