.Header {
    background: rgba(250, 235, 215, 0.20);
  padding: 5rem 0 0;
  width: 100%;
  height: 100vh;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 850px) {
        flex-direction: row;
        gap: 0;
    }

    &--contents-right {
      width: 100%;
        height: 100%;

      @media screen and (min-width: 1024px) {
        width: calc(100% - 485px);
        /* margin-left: 50px; */
      }

      .Header__text--title {
        font-size: 1.6rem;
        font-weight: 700;
        /* letter-spacing: var(--spacing); */
        font-family: freizeit_bold;

        @media screen and (min-width: 768px) {
            font-size: 2.5rem;
        }
      }

      .Header__text--desc {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        margin: 12px 0 36px;
        width: 100%;

        @media screen and (min-width: 768px) {
            font-size: 1.2rem;
            width: 80%;
        }
      }
    }

    &--contents-left {
      position: relative;
      width: 100%;
      height: 400px;
      /* margin-right: 70px; */

      @media screen and (min-width: 768px) {
          position: relative;
          width: 436px;
          height: 484px;
          /* margin-right: 70px; */
      }

      .Header__img--box {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0px;
        margin: 0px;
        padding: 0px;
        position: absolute;
        inset: 0px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          position: absolute;
          inset: 0px;
          box-sizing: border-box;
          padding: 0px;
          border: none;
          margin: auto;
          display: block;
          width: 0px;
          height: 0px;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
