.about {
  width: 100%;
  padding: 8rem 0 0rem;
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 0;
    }

    @media screen and (min-width: 850px) {
      flex-direction: row;
      /* gap: ; */
    }

    &--contents-right {
      @media screen and (min-width: 768px) {
        width: calc(100% - 100px);
        order: 1;
      }

      @media screen and (min-width: 1024px) {
        width: calc(100% - 100px);
        order: 1;
      }

      p {
        font-size: 1.1rem;
      }
    }

    &--contents-left {
      position: relative;
      width: 100%;
      height: 458px;
      /* margin-right: 70px; */

      @media screen and (min-width: 768px) {
        position: relative;
        /* width: 436px; */
        height: 550px;
        margin-right: 70px;
      }

      .About__img--box {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0px;
        margin: 0px;
        padding: 0px;
        position: absolute;
        inset: 0px;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0px;
          box-sizing: border-box;
          padding: 0px;
          border: none;
          margin: auto;
          display: block;
          width: 0px;
          height: 0px;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
