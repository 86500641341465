.accordion {
    padding: 3rem 0;

    &__container {
        width: 90%;
        background: var(--clr-primary-3);
        border-radius: var(--radius);
        padding: 2rem 0rem;
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 1rem 3rem;

        @media screen and (min-width: 768px) {
            grid-template-columns: 250px 1fr;
        }

        @media screen and (min-width: 1024px) {
            grid-template-columns: 350px 1fr;
        }

        @media screen and (min-width: 1400px) {
            grid-template-columns: 500px 1fr;
        }

        &--contents-left {
            align-self: baseline;

            img {
                width: 100%;
                height: 20rem;
                object-fit: contain;
            }
        }

        .question {
            padding: 1rem 1.5rem;
            border: 2px solid var(--clr-grey-special);
            margin-bottom: 1rem;
            border-radius: var(--radius);
            box-shadow: var(--light-shadow);
            transition: var(--transition);
            cursor: pointer;

            &:hover {
                box-shadow: var(--dark-shadow);
            }

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h4 {
                    text-transform: none;
                    line-height: 1.5;
                }
            }

            &-btn {
                background: transparent;
                border-color: transparent;
                width: 2rem;
                height: 2rem;
                background: #eae6eb;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #b4345c;
                cursor: pointer;
                margin-left: 1rem;
                align-self: center;
                min-width: 2rem;
                transition: all .5s ease-in-out;
            }
        }
    }
}