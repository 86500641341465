.footer {
  padding: 5rem;
  border-top: 1px solid hsla(350, 100%, 29%, 0.8);

  @media screen and (max-width: 850px) {
    padding: 5rem 1rem;
  }

  @media screen and (max-width: 450px) {
    padding: 5rem 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: stretch;
    gap: 1rem;

    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr
    }

    &--contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 2em;
      min-height: 15em;

      @media screen and (max-width: 850px) {
        padding: 0;
      }

      @media screen and (max-width: 450px) {
        padding: 0;
      }

      .footer__header {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 1rem;

        span {
          font-size: 1.3rem;
          font-weight: 500;
          text-align: center;
          color: black;
        }
      }

      .footer__texts {
        font-size: 0.85rem;
        margin-bottom: 1rem;
      }

      .footer__socials {
        display: flex;
        justify-content: space-around;
        gap: 2rem;
        font-size: 1.5rem;
        flex-direction: row;
        margin-top: 0.5em;
        color: var(--clr-primary-3);
        cursor: pointer;

        @media screen and (max-width: 850px) {
          font-size: 1.125rem;
        }

        @media screen and (max-width: 450px) {
          font-size: 1rem;
        }

      }
    }

    &--contents-links {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 450px) {
        display: block;
        margin-bottom: 3rem;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        li:not(:first-child) {
          margin-top: 0.8em;

          a {
            color: var(--clr-primary-6);
            text-decoration: none;

            &:hover {
              color: var(--clr-primary-5);
            }
          }
        }
      }
    }

    &--contents-form {
      textarea {
        width: 100%;
      }

      .alert.alert-success {
        background-color: #8bfc8b;
        color: #000;
        padding: 0.5em;
        border-radius: 5px;
        margin-bottom: 1em;
        text-align: center;
      }

      input,
      .footer-btn {
        font-size: 1em;
        padding: 1em;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 5px;
      }

      .footer-btn {
        border: transparent;
        background-color: var(--clr-primary-1);
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

.contact-page__textarea {
  height: 150px;
  width: calc(100% - 20px);
  resize: none;
  
}