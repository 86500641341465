/* Style for the card */
.card {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 50px;
    width: 400px;
    margin-top: 100px;
    margin-left: 450px;
}

/* Style for the form elements */
form {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    border: 1px solid #cccccc;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 10px;
    width: 100%;
}

button {
    background-color: #980525;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
}

button:hover {
    background-color: #0062cc;
}