/*
=============== 
Navbar
===============
*/
.nav {
  position: fixed;
  position: relative;
  width: 100%;
  transition: var(--transition);
  /* box-shadow: var(--dark-shadow); */
  /* background: transparent; */
  /* border: 1px solid rgba(255, 255, 255, 0.25); */
  z-index: var(--z-modal);
  backdrop-filter: blur(4px);
  --webkit-backdrop-filter: blur(4px);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0.5rem;
}

.nav__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: var(--z-modal);
}

.nav__logo img {
  width: 100px;
}

.nav__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: var(--z-modal);
}

.nav__link {
  color: var(--clr-primary-6);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
}

.nav__link:hover {
  background: transparent;
  color: var(--clr-primary-1);
  padding-left: 1.5rem;
}

.social-icons {
  display: none;
}

.nav__toggle {
  z-index: var(--z-modal);
  transition: var(--transition);
}

.nav__btn {
  font-size: 1.5rem;
  color: var(--clr-primary-1);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}

.nav-btn:hover {
  color: var(--clr-primary-2);
}

@media screen and (max-width: 650px) {
  .nav__menu {
    position: fixed;
    top: -350%;
    right: 0;
    width: 100%;
    padding: 4rem 0 1rem;
    transition: all 0.3s linear;
    background: rgb(255, 255, 255);
    backdrop-filter: blur(3px);
    z-index: var(--z-fixed);
    border-radius: 0 0 var(--radius) var(--radius);
  }

  .nav__list {
    margin-top: 2rem;
  }
}

/* show Menu toggle from classList in JS */
.show-menu {
  top: 0;
}

@media screen and (min-width: 767px) {
  .nav__list {
    flex-direction: row;
  }

  .nav__link {
    font-size: 1rem;
    padding: 0;
    margin: 0 0.8rem;
  }

  .nav__link:hover {
    padding: 0;
    background: transparent;
  }

  .nav__toggle {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .social-icons {
    display: flex;
  }

  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-);
    transition: var(--transition);
    font-size: 1.1rem;
  }

  .social-icons a:hover {
    color: var(--clr-primary-2);
  }
}
