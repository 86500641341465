/*
=============== 
Modal
===============
*/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    /* transition: var(--transition); */
    visibility: hidden;
    z-index: -1;
}

/* OPEN/CLOSE MODAL */
.show-modal {
    visibility: visible;
    z-index: 9999;
}

.modal-container {
    background: var(--clr-primary-4);
    border-radius: calc(.5rem + var(--radius));
    width: 90vw;
    /* height: 30vh; */
    max-width: var(--fixed-width);
    text-align: center;
    display: grid;
    place-items: center;
    position: relative;
    padding: 2.5rem 1rem;
}

.close-modal-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    background: transparent;
    border-color: transparent;
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    display: grid;
    place-items: center;
    color: var(--clr-primary-5);
    cursor: pointer;
}

.close-modal-btn:hover {
    color: var(--clr-primary-1);
    background-color: rgba(0, 0, 0, 0.04);
}
